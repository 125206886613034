<template>
  <div class="class-wrap">
    <a-spin :spinning="loading" size="large">
      <a-row>
        <a-col :xs="24" :sm="6">
          <h3 style="text-align: center">
            {{ className }}
            <span style="font-size: 12px">{{ getDdlLabel(timezoneList, classTimezone) }}</span>
          </h3>
          <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item ref="name" label="课程科目">
              <a-input
                v-model="subject"
                disabled
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item ref="name" label="课程名称">
              <a-input
                v-model="courseName"
                disabled
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item label="排课类型">
              <a-radio-group name="radioGroup" v-model="form.schedulingType" @change="changeSchedule">
                <a-radio value="SCHEDULING_NEW"> 新排课 </a-radio>
                <a-radio value="SCHEDULING_ADD"> 加课 </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="开始课节" prop="beginSectionId">
              <a-select
                show-search
                :filter-option="filterOption"
                option-filter-prop="children"
                v-model="form.beginSectionId"
                placeholder="请选择开始课节"
                @change="onSectionChange"
              >
                <a-select-option v-for="item in sectionOptions" :key="item.uuid" :value="item.uuid">
                  {{ item.sectionName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="结束课节" prop="endSectionId">
              <a-select
                show-search
                :filter-option="filterOption"
                option-filter-prop="children"
                v-model="form.endSectionId"
                placeholder="请选择结束课节"
                @change="onSectionChange"
                @dropdownVisibleChange="onEndSectionOpen()"
              >
                <a-select-option v-for="item in endSectionOptions" :key="item.uuid" :value="item.uuid">
                  {{ item.sectionName }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item ref="name" label="课节数">
              <a-input
                v-model="sectionCount"
                disabled
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
              />
            </a-form-model-item>
            <a-form-model-item label="开始日期" required prop="beginDate">
              <a-date-picker v-model="form.beginDate" placeholder="选择开课日期" style="width: 100%" />
            </a-form-model-item>
            <a-form-model-item
              ref="name"
              label="排课周期"
              prop="dayOfWeeksCheckbox"
              :label-col="labelCol"
              :wrapper-col="{ span: 24 }"
            >
              <div class="shedule-period-wrapper">
                <a-checkbox-group @change="dayOfWeeksChange" v-model="form.dayOfWeeksCheckbox">
                  <!-- <a-input
                v-model="form.dayOfWeeksCheckbox"
                style="display: none"
                @blur="
                  () => {
                    $refs.name.onFieldBlur();
                  }
                "
              /> -->
                  <a-row>
                    <a-col :span="6">
                      <a-checkbox :value="1"> 周一 </a-checkbox>
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start1"
                        @change="(date, dateString) => onWeekTimeChange(date, 1)"
                        placeholder="开始"
                        :inputReadOnly="true"
                        :disabled="!isInArr(1, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end1"
                        @change="(date, dateString) => onWeekTimeChange(date, 1, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(1, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="6">
                      <a-checkbox :value="2"> 周二 </a-checkbox>
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start2"
                        @change="(date, dateString) => onWeekTimeChange(date, 2)"
                        placeholder="开始"
                        :inputReadOnly="true"
                        :disabled="!isInArr(2, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end2"
                        @change="(date, dateString) => onWeekTimeChange(date, 2, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(2, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="6">
                      <a-checkbox :value="3"> 周三 </a-checkbox>
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start3"
                        @change="(date, dateString) => onWeekTimeChange(date, 3)"
                        placeholder="开始"
                        :inputReadOnly="true"
                        :disabled="!isInArr(3, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end3"
                        @change="(date, dateString) => onWeekTimeChange(date, 3, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(3, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="6">
                      <a-checkbox :value="4"> 周四 </a-checkbox>
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start4"
                        @change="(date, dateString) => onWeekTimeChange(date, 4)"
                        placeholder="开始"
                        :inputReadOnly="true"
                        :disabled="!isInArr(4, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end4"
                        @change="(date, dateString) => onWeekTimeChange(date, 4, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(4, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="6">
                      <a-checkbox :value="5"> 周五 </a-checkbox>
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start5"
                        @change="(date, dateString) => onWeekTimeChange(date, 5)"
                        placeholder="开始"
                        :inputReadOnly="true"
                        :disabled="!isInArr(5, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end5"
                        @change="(date, dateString) => onWeekTimeChange(date, 5, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(5, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="6">
                      <a-checkbox :value="6"> 周六 </a-checkbox>
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start6"
                        @change="(date, dateString) => onWeekTimeChange(date, 6)"
                        placeholder="开始"
                        :inputReadOnly="true"
                        :disabled="!isInArr(6, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end6"
                        @change="(date, dateString) => onWeekTimeChange(date, 6, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(6, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col :span="6">
                      <a-checkbox :value="7"> 周日 </a-checkbox>
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.start7"
                        @change="(date, dateString) => onWeekTimeChange(date, 7)"
                        placeholder="开始"
                        :inputReadOnly="true"
                        :disabled="!isInArr(7, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                    <a-col :span="6">
                      <a-time-picker
                        :minute-step="minuteStep"
                        :default-open-value="moment('00:00:00', 'HH:mm')"
                        format="HH:mm"
                        v-model="weekTime.end7"
                        @change="(date, dateString) => onWeekTimeChange(date, 7, true)"
                        placeholder="结束"
                        :inputReadOnly="true"
                        :disabled="!isInArr(7, form.dayOfWeeksCheckbox)"
                      />
                    </a-col>
                  </a-row>
                </a-checkbox-group>
              </div>
            </a-form-model-item>

            <a-tabs
              default-active-key="1"
              @change="callback"
              v-model="tabIndex"
              v-if="form.schedulingType === 'SCHEDULING_NEW'"
            >
              <a-tab-pane key="1" tab="老师属性">
                <a-form-model-item label="性别">
                  <a-select v-model="form.gender">
                    <a-select-option v-for="item in genderOptions" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="授课风格">
                  <a-select v-model="form.teachingStyleList" mode="multiple">
                    <a-select-option v-for="item in teachingStylesOptions" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <!-- <a-form-model-item label="能力星级" v-if="courseType !== 'PUBLIC'">
                  <a-select v-model="form.star">
                    <a-select-option v-for="item in starOptions" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item> -->
                <a-form-model-item label="授课语言">
                  <a-select v-model="form.language">
                    <a-select-option v-for="item in languageOptions" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="可授课程">
                  <a-select v-model="form.teacherLevels" mode="multiple">
                    <a-select-option v-for="item in teachableCourseOptions" :key="item.id" :value="item.id">
                      {{ item.structure }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-tab-pane>
              <a-tab-pane key="2" tab="指定老师" force-render v-if="showTeacherFlag">
                <a-form-model-item label="老师姓名">
                  <a-select
                    showSearch
                    v-model="assignedTeacherId"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="searchAssignedTeacher"
                  >
                    <a-select-option v-for="item in assignedTeacherOptions" :key="item.uuid" :value="item.uuid">
                      {{ item.fullName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-tab-pane>
            </a-tabs>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" @click="searchTeacher"> 搜索老师 </a-button>
              <!-- <a-button style="margin-left: 10px" @click="resetForm"> Reset </a-button> -->
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :xs="24" :sm="6">
          <h3>老师列表</h3>
          <a-radio-group v-model="form.teacherId" @change="onTeacherChange">
            <template v-for="(item, index) in teacherList">
              <a-popover :key="item.uuid" placement="rightTop" v-if="subject === 'CHINESE' ? index < 10 : true">
                <template slot="content">
                  <teacher-info :key="item.uuid" :teacherId="item.uuid" :subjectType="subjectType"></teacher-info>
                </template>
                <a-radio :style="radioStyle" :key="item.uuid" :value="item.uuid">
                  <span>{{ item.fullName }}</span>
                  <!-- <span v-if="item.labels.includes('SIGINING')">(已签约)</span> -->
                  <!-- <span style="float: right" v-if="courseType === 'TRIAL'">{{ item.star }}星</span>
                  <span style="float: right" v-if="courseType === 'STANDARD'">{{ item.standardStar }}星</span> -->
                </a-radio>
              </a-popover>
              <!-- <a-popover :title="item.fullName" :key="item.uuid" placement="rightTop">
                <template slot="content">
                  <div class="teacher-popover">
                    <div class="top">
                      <img :src="item.wkHeadImageUrl" alt="" />
                      <div class="top-right">
                        <div class="name-wrap">
                          <div class="name">
                            <span>{{ item.fullName }}</span>
                            <img v-if="item.gender === 'M'" src="" alt="" />
                            <img v-if="item.gender === 'F'" src="" alt="" />
                          </div>
                        </div>

                        <div class="teaching-style">
                          <li v-for="styleItem in item.teachingStyles" :key="styleItem">#{{ styleItem }}</li>
                        </div>
                      </div>
                    </div>
                    <div class="middle">
                      <span class="info-title">教师简介</span>
                      <p v-if="item.desc">{{ item.desc }}</p>
                      <p v-else>暂无内容</p>
                      <span class="info-title">教学理念</span>
                      <p v-if="item.teachingPhilosophy">{{ item.teachingPhilosophy }}</p>
                      <p v-else>暂无内容</p>
                    </div>
                    <a-divider dashed>以下信息</a-divider>
                    <div class="bottom">
                      <p>
                        <span><b>工作状态：</b>{{ getDdlLabel(scheduleOptions.workStatus, item.workStatus) }}</span
                        ><span class="item-container"
                          ><b>授课语言：</b>{{ (item.languages && item.languages.join(',')) || '' }}</span
                        >
                      </p>
                      <p>
                        <span class="item-container"><b>教龄 (年)：</b>{{ item.teachingAge }}</span
                        ><span><b>最高学历：</b>{{ item.degree }}</span>
                      </p>
                      <p>
                        <span class="item-container"
                          ><b>可授课程：</b>{{ (item.courseStructures && item.courseStructures.join(',')) || '' }}</span
                        >
                      </p>
                      <div class="modal-btn-group">
                        <a-popover :getPopupContainer="getPopupContainer">
                          <template slot="content">
                            <div v-if="liveUrlList.length > 0">
                              <div
                                v-for="(item, index) in liveUrlList"
                                class="play-item"
                                :key="index"
                                @click="hrefLiveUrl(item)"
                              >
                                {{ item.scheduleName }}
                              </div>
                            </div>
                            <div v-else>暂无课节回放</div>
                          </template>
                          <a-button type="primary" @mouseenter="getPlayLiveUrl(item)">课节回放 </a-button>
                        </a-popover>

                        <a :href="item.assessmentVideoRecord" target="_blank" v-if="item.assessmentVideoRecord">
                          <a-button type="primary"> 考核影像记录 </a-button>
                        </a>
                        <a-button type="primary" @click="toClassCalendar(item)"> 老师课表 </a-button>
                      </div>
                    </div>
                  </div>
                </template>
                <a-radio :style="radioStyle" :key="item.uuid" :value="item.uuid">
                  <span>{{ item.fullName }}</span>
                  <span v-if="item.labels.includes('SIGINING')">(已签约)</span>
                  <span style="float: right" v-if="courseType === 'TRIAL'">{{ item.star }}星</span>
                  <span style="float: right" v-if="courseType === 'STANDARD'">{{ item.standardStar }}星</span>
                </a-radio>
              </a-popover> -->
            </template>
            <div v-if="teacherList.length === 0 && form.schedulingType === 'SCHEDULING_ADD' && searchFlag">
              {{ teacherListTxt }}
            </div>
          </a-radio-group>
        </a-col>
        <a-col :xs="24" :sm="12">
          <h3>排课结果</h3>
          <a-row :gutter="24">
            <a-col :span="8">排课频度：一周{{ form.dayOfWeeksCheckbox.length }}次</a-col>
            <a-col :span="8">授课老师：{{ selectedTeacherName }}</a-col>
            <a-col :span="8">老师时区：{{ getDdlLabel(timezoneList, selectedTeacherTimezone) }}</a-col>
          </a-row>
          <a-row :gutter="24">
            <a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
              <a-col :span="8">
                <a-form-model-item label="设置助教">
                  <a-select
                    showSearch
                    v-model="assistant"
                    @search="queryAssistantOptions"
                    :filter-option="filterOption"
                    :disabled="!scheduleList.length"
                    option-filter-prop="children"
                    :dropdownMatchSelectWidth="false"
                    @change="onAssistantChange"
                  >
                    <a-select-option v-for="item in assistantOptions" :key="item.value" :value="item.value">
                      {{ item.label }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col :span="8">
                <a-form-model-item label="是否代课">
                  <a-radio-group name="radioGroup" :default-value="false" v-model="substitute">
                    <a-radio :value="true"> 是 </a-radio>
                    <a-radio :value="false"> 否 </a-radio>
                  </a-radio-group>
                </a-form-model-item>
              </a-col>
            </a-form-model>
          </a-row>
          <p>批量排课时间:</p>
          <a-row :gutter="24">
            <a-form-model :label-col="{ span: 8 }" :wrapper-col="{ span: 16 }">
              <a-col :span="8" v-for="item in form.dayOfWeeksCheckbox" :key="item">
                <a-form-model-item :label="`每${getDdlLabel(dayOfWeekOptions, item)}`">
                  <a-time-picker
                    :minute-step="minuteStep"
                    v-model="weekTime[`start${item}`]"
                    :default-open-value="moment('00:00:00', 'HH:mm')"
                    format="HH:mm"
                    @change="(date, dateString) => onStartTimeChange(date, item)"
                    :inputReadOnly="true"
                  />
                </a-form-model-item>
              </a-col>
            </a-form-model>
          </a-row>
          <a-button type="primary" @click="onScheduleSubmit" style="float: right; margin: 15px 0"> 确认排课 </a-button>
          <a-divider />
          <p style="color: red">*可以微调每节课时间，红色记录表示时间有冲突</p>
          <a-table
            :columns="columns"
            :data-source="scheduleList"
            :pagination="false"
            :loading="loading"
            @change="handleTableChange"
            bordered
          >
            <span
              slot="startDateTime"
              slot-scope="text, record, index"
              :class="record.isConflicted ? 'conflictedRow' : ''"
            >
              <a-time-picker
                :minute-step="minuteStep"
                :default-open-value="moment('00:00:00', 'HH:mm')"
                format="HH:mm"
                :default-value="text"
                v-model="record.startDateTime"
                @change="onTimeChange(index)"
                :inputReadOnly="true"
              />
            </span>
          </a-table>
        </a-col>
      </a-row>
      <a-modal v-model="isVisible" :title="error.message" @ok="handleOk" v-if="isVisible">
        <p v-for="item in error.data.list" :key="item">{{ item }}</p>
      </a-modal>
      <!--私教课，节假日期间排课自动跳过-->
      <a-modal
        v-model="showSkipHoliday"
        v-if="showSkipHoliday"
        title="操作提示"
        okText="跳过该时间,完成排课"
        cancelText="取消本次排课"
        @ok="handleSkipHoliday"
        @cancel="cancelSkipHoliday"
      >
        <div>
          {{ error.errors && error.errors.holiday }}
        </div>
      </a-modal>
      <a-modal
        v-model="isAddClassVisible"
        title="提示"
        @cancel="cancelAddClass"
        okText="是"
        cancelText="否"
        @ok="handleOkClass"
      >
        <p>系统检测到该老师属于加课范畴，请问你是要加课吗？</p>
      </a-modal>
    </a-spin>
    <a-modal v-model="isCheckClassVisible" title="提示" :footer="null">
      <p>
        注意：本次操作影响课节授课老师中不包含“<span v-for="(item, index) in structuresList" :key="index"
          >{{ item.structure }}<span v-if="structuresList.length !== index + 1">、</span></span
        >”级别
      </p>
      <p style="color: #868585">可取消本次排课，也可继续排课</p>
    </a-modal>
    <a-modal v-model="isTeacherVisible" title="提示" okText="发排课单" cancelText="取消" @ok="hrefSendClass">
      <p>系统暂无符合条件的授课老师，可前去发<span style="color: #04cb94">排课单</span>，排课信息不可编辑需慎重</p>
      <p style="color: #868585">也可取消本次排课</p>
    </a-modal>
    <!--紧急代课单-->
    <a-modal v-model="isUrgentVisible" title="提示" okText="发排课单" cancelText="取消" @ok="hrefSendClass">
      <p>
        本次排课包含<span style="color: red">24小时内课节</span>不可直接排课，可发<span style="color: #04cb94"
          >排课单</span
        >，排课信息不可编辑需慎重
      </p>
      <p style="color: #868585">也可取消本次排课</p>
    </a-modal>
    <!--指定老师发单-->
    <a-modal v-model="isBillVisible" title="提示" okText="指定发单" cancelText="取消" @ok="hrefBillClass">
      <p>
        本次操作包含<span style="color: red">24小时内课节</span>，可<span style="color: #04cb94"
          >指定老师发单（{{ selectedTeacherName }}）</span
        >，课节信息不可编辑需慎重
      </p>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment';
import debounce from 'lodash.debounce';
import storage from 'store';
import {
  postReq,
  getReq,
  loadScheduleOptions,
  loadTimezoneList,
  checkTeacherClass,
  checkUrgentClass,
  getOrderAgainSheetDetail,
} from '@/api/schedule';
import { genderOptions, teachingStylesOptions, languageOptions, dayOfWeekOptions, starOptions } from '@/utils/const';
import { getDdlLabel } from '@/utils/util';
import { getTeacherSchedule } from '@/api/headTeacher';
import TeacherInfo from '@/views/schedule/teacherInfo';

const roles = storage.get('roles');
const columns = [
  // 排课列表
  {
    title: '序号',
    key: 'index',
    dataIndex: 'num',
    customRender: (text, record, index) => `${index + 1}`,
  },
  {
    title: '课节',
    dataIndex: 'courseSectionName',
    key: 'courseSectionName',
  },
  {
    title: '课节时长',
    key: 'courseSectionDuration',
    dataIndex: 'courseSectionDuration',
    customRender: (text, record, index) => `${text}m`,
  },
  {
    title: '日期',
    dataIndex: 'startDate',
    key: 'startDate',
    customRender: (text, record, index) => `${text} ${getDdlLabel(dayOfWeekOptions, record.dayOfWeek)}`,
  },
  {
    title: '老师可用时间',
    key: 'timeBlockAvailable',
    dataIndex: 'timeBlockAvailable',
  },
  {
    title: '排课时间',
    key: 'startDateTime',
    dataIndex: 'startDateTime',
    width: 120,
    scopedSlots: { customRender: 'startDateTime' },
  },
];
export default {
  components: {
    TeacherInfo,
  },
  data() {
    this.searchAssignedTeacher = debounce(this.searchAssignedTeacher, 500);
    return {
      relationGroupId: '',
      scheduleOrderId: '',
      isBillVisible: false,
      standardType: null,
      userInfo: {},
      showTeacherFlag: false,
      isUrgentVisible: false,
      isTeacherVisible: false,
      structuresList: [],
      isCheckClassVisible: false,
      availableTimeParams: {},
      showSkipHoliday: false,
      skipHoliday: false,
      searchFlag: false,
      isAddClassVisible: false, // 加课逻辑的二次确认弹窗
      teacherListTxt: '',
      subjectType: '',
      columns,
      liveUrlList: [],
      loading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 16 },
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      other: '',
      classId: this.$route.query.classId,
      courseId: this.$route.query.courseId,
      dayOfWeekOptions,
      getDdlLabel,
      scheduleOptions: {
        workStatus: [],
      },
      timezoneList: [],
      form: {
        // 搜索老师表单
        beginSectionId: null,
        endSectionId: null,
        beginDate: null,
        gender: null,
        teachingStyleList: [],
        // star: null,
        language: this.$route.query.teachingLanguage,
        teacherLevels: [],
        dayOfWeeksCheckbox: [],
        dayOfWeeks: {},
        formClassId: this.$route.query.classId,
        teacherId: '',
        assignedTeacher: false,
        teacherName: null,
        schedulingType: null,
      },
      rules: {
        // 搜索老师表单验证
        beginSectionId: [{ required: true, message: '请选择开始课节', trigger: 'change' }],
        endSectionId: [{ required: true, message: '请选择结束课节', trigger: 'change' }],
        beginDate: [{ required: true, message: '请选择开始日期', trigger: 'change' }],
        dayOfWeeksCheckbox: [
          {
            type: 'array',
            required: true,
            message: '请选择排课周期',
            trigger: 'change',
          },
        ],
      },
      tabIndex: '1',
      courseName: null,
      subject: null,
      className: null,
      classType: null,
      courseType: null,
      classTimezone: null,
      teachableCourseOptions: [],
      sectionCount: 0,
      assignedTeacherId: null,
      sectionOptions: [],
      endSectionOptions: [],
      genderOptions,
      teachingStylesOptions,
      languageOptions,
      dayOfWeekOptions,
      starOptions,
      assignedTeacherOptions: [],
      weekTime: {
        start1: null,
        end1: null,
        start2: null,
        end2: null,
        start3: null,
        end3: null,
        start4: null,
        end4: null,
        start5: null,
        end5: null,
        start6: null,
        end6: null,
        start7: null,
        end7: null,
      },
      minuteStep: roles.includes('TEACHING_STAFF') ? 1 : 20,
      teacherList: [],
      scheduleList: [],
      assistantOptions: [],
      assistant: null,
      selectedTeacherName: null,
      selectedTeacherCode: null,
      selectedTeacherId: null,
      selectedTeacherTimezone: null,
      substitute: false,
      error: {
        data: {
          list: [],
        },
        message: null,
      },
      isVisible: false,
    };
  },
  created() {
    this.scheduleOrderId = this.$route.query.scheduleOrderId;
    const obj = storage.get('userInfo');
    this.userInfo = obj;
    // 判断按钮角色
    const roleFLag = this.userInfo.roleArr.find((role) => role === 'TEACHING_STAFF' || role === 'TEACHING_STAFF_ADMIN');
    // 只有教务跟教务主管才有权限
    if (roleFLag === 'TEACHING_STAFF' || roleFLag === 'TEACHING_STAFF_ADMIN') {
      this.showTeacherFlag = true;
    }
    // 判断如果是从再次发单过来的数据
    const type = this.$route.query.formType;
    if (type === 'bill') {
      this.queryBillDetail();
    } else {
      this.queryScheduleDetail();
      this.querySectionList();
    }

    this.loadScheduleOptions();
    this.loadTimezoneList();
  },
  methods: {
    moment,
    getOrderAgainSheetDetail() {
      const params = {
        scheduleOrderId: this.scheduleOrderId,
      };
      getOrderAgainSheetDetail(params).then((res) => {
        console.log(res);
        const { data } = res;
        this.courseId = data?.formClassRespDTO.courseId;
        this.querySectionList();
        this.relationGroupId = data?.scheduleOrderRespDTO?.relationGroupId;

        this.courseName = data?.formClassRespDTO?.course?.courseName;
        this.subject = data?.formClassRespDTO?.course?.subject;
        this.classType = data?.scheduleOrderRespDTO?.businessData?.classType;
        this.courseType = data?.scheduleOrderRespDTO?.businessData?.courseType;
        this.standardType = data?.formClassRespDTO?.course?.standardType;
        this.className = data?.scheduleOrderRespDTO?.formClassName;
        this.classTimezone = data?.formClassRespDTO?.timezone;
        this.form.beginSectionId = data?.scheduleOrderRespDTO?.businessData?.beginSectionId;
        // 算结束课节
        this.onEndSectionOpen();

        this.form.endSectionId = data?.scheduleOrderRespDTO?.businessData?.endSectionId;

        this.sectionCount = data?.scheduleOrderRespDTO?.businessData?.sectionCount;
        this.form.schedulingType = data?.scheduleOrderRespDTO?.businessData?.schedulingType;
        this.form.beginDate = this.moment(data?.formClassRespDTO?.latestCourseScheduleStartTime).add(1, 'd');
        this.subjectType = data?.formClassRespDTO?.course?.subjectType.nodeContent.value;
        this.form.teacherLevels = data?.scheduleOrderRespDTO?.businessData?.teacherLevels;

        const obj = data?.scheduleOrderRespDTO?.businessData?.dayOfWeeks;
        if (obj) {
          for (const key in obj) {
            this.form.dayOfWeeksCheckbox.push(parseInt(key));
            this.weekTime[`end${key}`] = moment(obj[key][1], 'HH:mm');
            this.weekTime[`start${key}`] = moment(obj[key][0], 'HH:mm');
            // 设置初始默认时间
            //   this.form.dayOfWeeks[key] =[obj[key][0]];
            // 设置初始默认时间
            this.onWeekTimeChange(this.weekTime[`start${key}`], key);
            this.onWeekTimeChange(this.weekTime[`end${key}`], key, true);
          }
        }

        if (this.form.schedulingType === 'SCHEDULING_NEW') {
          this.queryCourseStructure();
        }
      });
    },
    queryBillDetail() {
      // 调接口去获取详情
      this.getOrderAgainSheetDetail();
    },
    queryScheduleDetail() {
      this.loading = true;
      getReq(`/api/admin/scheduling/form_class/${this.classId}`, {})
        .then((res) => {
          const data = res.data.content;
          this.courseName = data.course.courseName;
          this.subject = data.course.subject;
          this.classType = data.classType;
          this.courseType = data.courseType;
          this.standardType = data.course.standardType;
          this.className = data.className;
          this.classTimezone = data.timezone;
          this.form.beginSectionId = data.proposedSectionId;
          this.form.schedulingType = data.historyCourseSchedule ? 'SCHEDULING_ADD' : 'SCHEDULING_NEW';
          this.form.beginDate = this.moment(data.latestCourseScheduleStartTime).add(1, 'd');
          this.subjectType = data.course.subjectType.nodeContent.value;

          if (data.dayOfWeeks && data.dayOfWeeks.length > 0) {
            data.dayOfWeeks.forEach((item) => {
              this.form.dayOfWeeksCheckbox.push(item.dayOfWeek);
              this.weekTime[`end${item.dayOfWeek}`] = moment(item.endTime, 'HH:mm');
              this.weekTime[`start${item.dayOfWeek}`] = moment(item.startTime, 'HH:mm');
              console.log(this.weekTime);
              // 设置初始默认时间
              this.onWeekTimeChange(this.weekTime[`start${item.dayOfWeek}`], item.dayOfWeek);
              this.onWeekTimeChange(this.weekTime[`end${item.dayOfWeek}`], item.dayOfWeek, true);
            });
          }
          if (this.form.schedulingType === 'SCHEDULING_NEW') {
            this.queryCourseStructure();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadScheduleOptions() {
      loadScheduleOptions((res) => {
        this.scheduleOptions = res;
      });
    },
    loadTimezoneList() {
      loadTimezoneList((res) => {
        this.timezoneList = res;
      });
    },
    // 查询课节下拉选项列表
    querySectionList() {
      postReq('/api/admin/scheduling/course/section/search', {
        courseId: this.courseId,
        formClassId: this.classId,
      }).then((res) => {
        const data = res.data.content.filter((item) => item.sectionNeedShow);
        this.sectionOptions = data;
        if (this.$route.query.formType === 'bill') {
          this.endSectionOptions = data;
        }
      });
    },
    queryCourseStructure() {
      // 可授课程
      postReq('/api/admin/scheduling/course_structure/search', { data: { subject: this.subject } }).then((res) => {
        this.teachableCourseOptions = res.data.content;
      });
    },
    // 指定老师发单
    hrefBillClass() {
      const sectionIds = [];
      const sectionTimes = [];
      this.scheduleList.forEach((item) => {
        sectionIds.push(item.courseSectionId);

        sectionTimes.push({
          courseSectionId: item.courseSectionId,
          courseSectionName: item.courseSectionName,
          dayOfWeek: item.dayOfWeek,
          duration: item.courseSectionDuration,
          endDateTime: moment(item.startDateTime)
            .add(item.courseSectionDuration, 'minutes')
            .format('YYYY-MM-DD HH:mm:ss'),
          startDateTime: moment(item.startDateTime).format('YYYY-MM-DD HH:mm:ss'),
          timeout: item.courseTimeOut,
        });
      });
      console.log(sectionIds);
      const classItem = {
        courseId: this.courseId,
        formClassName: this.className,
        classType: this.classType,
        formClassId: this.$route.query.classId,
        courseType: this.courseType,
        courseName: this.courseName,
        teacherName: this.selectedTeacherName,
        teacherId: this.selectedTeacherId,
        teacherCode: this.selectedTeacherCode,
        schedulingSource: this.form.schedulingType,
        sectionIds,
        sectionTimes,
        relationGroupId: this.relationGroupId,
        scheduleOrderId: this.scheduleOrderId,
      };
      this.$router.push({
        path: '/workoutSchedule/courseTeacherSheet',
        query: {
          classItem: JSON.stringify(classItem),
          subject: this.subject,
        },
      });
    },
    // 发紧急代课单
    hrefUrgentClass() {},
    // 跳转发排课单
    hrefSendClass() {
      const classItem = {
        courseId: this.courseId,
        formClassName: this.className,
        classType: this.classType,
        formClassId: this.$route.query.classId,
        courseType: this.courseType,
        courseName: this.courseName,
        beginSectionId: this.form.beginSectionId,
        endSectionId: this.form.endSectionId,
        sectionCount: this.sectionCount,
        courseDate: this.form.beginDate,
        dayOfWeeksCheckbox: this.form.dayOfWeeksCheckbox,
        gender: this.form.gender,
        teachingStyle: this.form.teachingStyleList,
        language: this.form.language,
        dayOfWeeks: this.form.dayOfWeeks,
        classTimezone: this.classTimezone,
        beginDate: moment(this.form.beginDate).format('YYYY-MM-DD'),
        teacherLevels: this.form.teacherLevels,
        schedulingType: this.form.schedulingType,
        relationGroupId: this.relationGroupId,
        scheduleOrderId: this.scheduleOrderId,
      };
      this.$router.push({
        path: '/workoutSchedule/courseScheduleSheet',
        query: {
          classItem: JSON.stringify(classItem),
          subject: this.subject,
        },
      });
    },
    cancelSkipHoliday() {
      this.showSkipHoliday = false;
      this.skipHoliday = false;
    },
    handleSkipHoliday() {
      this.skipHoliday = true;
      this.queryAvailableTime();
    },
    changeSchedule() {
      this.teacherList = [];
      this.searchFlag = false;
      // 新排课的才请求可授课程
      if (this.teachableCourseOptions.length === 0 && this.form.schedulingType === 'SCHEDULING_NEW') {
        this.queryCourseStructure();
      }
    },
    cancelAddClass() {
      this.isAddClassVisible = false;
    },
    handleOkClass() {
      // 走新排课，但是没有符合的，所以走加课逻辑
      this.form.schedulingType = 'SCHEDULING_ADD';
      this.form.assignedTeacher = true;
      this.onScheduleSubmit('addClass');
    },
    getPopupContainer(ele) {
      // 解决hover里面悬浮，外面 的弹窗关闭问题
      return ele.parentElement;
    },
    getPlayLiveUrl(item) {
      this.liveUrlList = [];
      const params = {
        teacherId: item.uuid,
        subjectType: this.subjectType,
      };
      getTeacherSchedule(params).then((res) => {
        this.liveUrlList = res.data.content;
      });
    },

    // 获取回放课节
    hrefLiveUrl(item) {
      window.open(item.liveUrl, '_blank');
    },
    toClassCalendar(item) {
      console.log(item);
      const routeUrl = this.$router.resolve({
        path: '/workoutSchedule/teacherClassCalendar',
        query: { teacherName: item.fullName, teacherId: item.uuid, timezone: item.timezone },
      });
      window.open(routeUrl.href, '_blank');
    },
    // 获取助教老师
    queryAssistantOptions(name) {
      postReq('/api/account/ref/teaching_assistant', { name }).then((res) => {
        this.assistantOptions = res.data.content;
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },

    onChange(checkedValues) {
      console.log('checked = ', checkedValues);
    },
    callback() {},
    onEndSectionOpen() {
      const index = this.sectionOptions.findIndex((val) => {
        return val.uuid === this.form.beginSectionId;
      });
      this.endSectionOptions = this.sectionOptions.slice(index < 0 ? 0 : index);
    },
    onSectionChange(e) {
      this.getSectionInfo();
    },
    getSectionInfo() {
      if (!!this.form.beginSectionId && !!this.form.endSectionId) {
        getReq(
          `/api/admin/scheduling/section/info/v2/${this.classId}/${this.form.beginSectionId}/${this.form.endSectionId}`,
          {},
        ).then((res) => {
          this.sectionCount = res.data.content.activeCount;
          this.form.teacherLevels = res.data.content.activeLevels;
        });
      }
    },
    dayOfWeeksChange(value) {
      this.form.dayOfWeeksCheckbox = value;
    },
    onWeekTimeChange(date, index, setEndTime) {
      const arr = [].concat(this.form.dayOfWeeks[index]);
      if (setEndTime) {
        // 结束时间设置
        if (!arr[0]) arr[0] = null;
        arr[1] = date == null ? null : date.format('HH:mm');
      } else {
        // 开始时间设置
        arr[0] = date == null ? null : date.format('HH:mm');
        arr[1] = arr[0];
        if (!arr[1]) arr[1] = null;
        this.weekTime[`end${index}`] = date;
      }
      this.form.dayOfWeeks[index] = arr;
    },
    isInArr(item, arr) {
      return arr.includes(item);
    },
    searchTeacher(e) {
      this.isTeacherVisible = false;
      this.form.teacherId = null;
      // tab切换 更改校验规则，指定老师
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 验证通过
          const params = { ...this.form };
          console.log(params);
          this.processDayOfWeeksParam(params);
          params.beginDate = moment(params.beginDate).format('YYYY-MM-DD');
          // 排课类型为加课
          if (this.form.schedulingType === 'SCHEDULING_ADD') {
            this.loading = true;
            postReq('/api/admin/scheduling/available_teachers/add', params)
              .then((res) => {
                this.searchFlag = true;

                this.teacherList = res.data;

                if (this.teacherList.length === 0) {
                  this.teacherListTxt = '没有符合加课条件的老师';
                  // 数学不可发排课单
                  if (this.subject === 'CHINESE') {
                    this.isTeacherVisible = true;
                  }
                }
              })
              .finally(() => {
                this.loading = false;
              });
          } else if (this.tabIndex == 2) {
            if (!this.assignedTeacherId) {
              this.$message.warning('老师姓名不能为空!');
              return false;
            }
            this.loading = true;
            postReq(`/api/admin/scheduling/assigned_teacher/${this.assignedTeacherId}`, params)
              .then((res) => {
                this.teacherList = res.data;
                if (this.teacherList.length === 0 && this.subject === 'CHINESE') {
                  this.isTeacherVisible = true;
                }
              })
              .finally(() => {
                this.loading = false;
              });
          } else {
            this.loading = true;
            postReq('/api/admin/scheduling/available_teachers', params)
              .then((res) => {
                this.teacherList = res.data;
                if (this.teacherList.length === 0 && this.subject === 'CHINESE') {
                  this.isTeacherVisible = true;
                }
              })
              .finally(() => {
                this.loading = false;
              });
          }
        } else {
          // 验证失败
          console.log('error submit!!');
        }
      });
    },

    processDayOfWeeksParam(params) {
      const tempDayOfWeeks = {};
      for (let i = 0; i < params.dayOfWeeksCheckbox.length; i++) {
        const element = params.dayOfWeeksCheckbox[i];
        tempDayOfWeeks[element] = params.dayOfWeeks[element] || [null, null];
      }
      params.dayOfWeeks = { ...tempDayOfWeeks };
    },
    queryAvailableTime() {
      // 私教课春节假期期间排课是自动跳过
      this.availableTimeParams.skipHoliday = this.skipHoliday;
      postReq('/api/admin/scheduling/available_time/', this.availableTimeParams, { showErrorType: 'modal' })
        .then((res) => {
          this.scheduleList = res.data.content;
          this.cancelSkipHoliday();
        })
        .catch((err) => {
          if (err?.response?.data && err?.response?.data?.errors?.holiday) {
            this.error = err.response.data;
            this.showSkipHoliday = true;
          }

          if (err?.response?.data?.data) {
            this.error = err?.response?.data;
            this.isVisible = true;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 老师列表 单选点击
    onTeacherChange(e) {
      this.scheduleList = [];
      this.form.teacherId = e.target.value;

      // 校验当前排课级别与指定的授课老师可排级别
      // 匹配所选择的课节
      let startIndex = -1;
      let endIndex = -1;
      this.sectionOptions.map((item, index) => {
        if (item.uuid === this.form.beginSectionId) {
          startIndex = index;
        }
        if (item.uuid === this.form.endSectionId) {
          endIndex = index;
        }
      });
      const paramsObj = {
        teacherId: this.form.teacherId,
        courseSectionIds: this.sectionOptions.slice(startIndex, endIndex + 1).map((item) => {
          return item.uuid;
        }),
        formClassId: this.$route.query.classId,
      };
      checkTeacherClass(paramsObj).then((res) => {
        const { data } = res;
        if (data.canTeach === false) {
          this.structuresList = data.structures;
          this.isCheckClassVisible = true;
        }
      });

      // 设置 排课结果-授课老师&老师时区
      const currentTeacher = this.teacherList.filter((item) => item.uuid === e.target.value);

      this.selectedTeacherName = currentTeacher[0].fullName;
      this.selectedTeacherCode = currentTeacher[0].code;
      this.selectedTeacherId = currentTeacher[0].uuid;
      this.selectedTeacherTimezone = currentTeacher[0].timezone;
      // 预排课列表接口参数
      const params = { ...this.form };
      this.processDayOfWeeksParam(params);
      params.beginDate = moment(params.beginDate).format('YYYY-MM-DD');

      if (this.tabIndex == 2 || this.form.schedulingType === 'SCHEDULING_ADD') {
        params.assignedTeacher = true;
      }
      this.loading = true;
      this.availableTimeParams = params;
      this.queryAvailableTime();
    },

    searchAssignedTeacher(e) {
      postReq('/api/admin/teacher/search/1', { fullName: e, newScheduleEnable: true, subject: this.subject }).then(
        (res) => {
          this.assignedTeacherOptions = res.data.content;
        },
      );
    },
    // 排课结果-设置助教
    onAssistantChange(assistantId) {
      for (let i = 0; i < this.scheduleList.length; i++) {
        const element = this.scheduleList[i];
        element.assistant = assistantId;
      }
    },
    // 排课结果-批量排课时间
    onStartTimeChange(time, index) {
      const timeStr = time.format('YYYY-MM-DD HH:mm');
      const curTime = timeStr.split(' ')[1];
      for (let i = 0; i < this.scheduleList.length; i++) {
        if (this.scheduleList[i].dayOfWeek == index) {
          const schduleItem = this.scheduleList[i];
          const timeArr =
            typeof schduleItem.startDateTime === 'string'
              ? schduleItem.startDateTime.split(' ')
              : schduleItem.startDateTime.format('YYYY-MM-DD HH:mm').split(' ');
          schduleItem.startDateTime = `${timeArr[0]} ${curTime}`;
          if (this.isTimeConflict(time, schduleItem.timeBlockAvailable)) {
            schduleItem.isConflicted = true;
          } else {
            schduleItem.isConflicted = false;
          }
        }
      }
    },
    // 排课结果-预排课列表 修改时间事件
    onTimeChange(index) {
      const curItem = this.scheduleList[index];
      if (this.isTimeConflict(curItem.startDateTime, curItem.timeBlockAvailable)) {
        curItem.isConflicted = true;
      } else {
        curItem.isConflicted = false;
      }
    },
    // 判断修改时间是否与老师可用时间冲突
    isTimeConflict(curTime, availableTimeBlock) {
      const availableTimeArr = (!!availableTimeBlock && availableTimeBlock.split(' ')) || [];
      const timeStr = curTime.format('YYYY-MM-DD HH:mm');
      const timeSubStr = timeStr.split(' ')[1];
      const timeArr = timeSubStr.split(':');
      const curHour = +timeArr[0];
      const curMinute = +timeArr[1];

      for (let i = 0; i < availableTimeArr.length; i++) {
        const item = availableTimeArr[i];
        const timeItemArr = item.split('-');
        const minHour = +timeItemArr[0].split(':')[0];
        const minMinute = +timeItemArr[0].split(':')[1];
        let maxHour = +timeItemArr[1].split(':')[0];
        let maxMinute = +timeItemArr[1].split(':')[1];
        // 如果跨天，默认为当天时间的最后一分钟
        if (timeItemArr[1].indexOf('C') > -1) {
          maxHour = 23;
          maxMinute = 59;
        }
        if (curHour > minHour && curHour < maxHour) {
          return false;
        }
        if (curHour === minHour) {
          if (curMinute >= minMinute) {
            return false;
          }
        }
        if (curHour === maxHour) {
          if (curMinute <= maxMinute) {
            return false;
          }
        }
      }
      return true;
    },
    // 确认排课
    async onScheduleSubmit(addFlag) {
      if (this.scheduleList.length === 0) {
        return false;
      }
      console.log(this.tabIndex);
      // 只有为新排课，加课搜索老师属性才去去判断,科目为中文
      if (
        ((this.form.schedulingType === 'SCHEDULING_NEW' && this.tabIndex == 1) ||
          this.form.schedulingType === 'SCHEDULING_ADD') &&
        this.subject === 'CHINESE'
      ) {
        const paramsObj = {
          dateTime: moment(this.scheduleList[0].startDateTime).format('YYYY-MM-DD HH:mm'), // 时间
          timezone: this.classTimezone, // 时区
        };
        const urgentObj = await checkUrgentClass(paramsObj);

        if (urgentObj.data.content) {
          // 紧急排课单，展示24小时的弹窗
          if (this.form.schedulingType === 'SCHEDULING_NEW') {
            this.isUrgentVisible = true;
          } else if (this.form.schedulingType === 'SCHEDULING_ADD' && this.subject === 'CHINESE') {
            // 加课，指定老师发单
            this.isBillVisible = true;
          }

          return false;
        }
      }

      // 确认排课
      const paramArr = [].concat(this.scheduleList);
      for (let i = 0; i < paramArr.length; i++) {
        paramArr[i].substitute = this.substitute;
        if (typeof paramArr[i].startDateTime !== 'string') {
          paramArr[i].startDateTime = paramArr[i].startDateTime.format('YYYY-MM-DD HH:mm');
        }
      }
      console.log(paramArr);
      const objParams = {
        assignedTeacher: paramArr[0].assignedTeacher,
        // assistant: paramArr[0].assistant,
        assistant: this.assistant,
        formClassId: paramArr[0].formClassId,
        inner: paramArr[0].inner,
        onlyUrgent: paramArr[0].onlyUrgent,
        schedulingType: this.form.schedulingType,
        substitute: this.substitute,
        teacherId: paramArr[0].teacherId,
        teacherName: paramArr[0].teacherName,
        timeSplitItemList: paramArr,
        courseType: this.courseType,
        standardType: this.standardType,
      };
      // 选择加课
      if (addFlag === 'addClass') {
        objParams.assignedTeacher = true;
      }
      this.loading = true;
      postReq('/api/admin/scheduling/schedule/v2', objParams)
        .then((res) => {
          console.log(res);
          // 判断要不要走加课的逻辑
          if (this.form.schedulingType === 'SCHEDULING_NEW' && res.data.reminder) {
            this.isAddClassVisible = true;
          } else {
            this.$router.push({
              path: 'classDetail',
              query: { classId: this.$route.query.classId },
            });
          }
        })
        .catch((err) => {
          if (!err.response.data.data) return;
          this.error = err.response.data;
          this.isVisible = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleTableChange() {
      console.log('table changed');
    },
    handleOk() {
      this.isVisible = false;
    },
  },
};
</script>
<style lang="less">
.ant-time-picker {
  width: auto;
}
.shedule-period-wrapper .ant-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 5px;
}
.ant-form-item {
  margin-bottom: 0px;
}
.schedule-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  span {
    flex: 1;
  }
}
.conflictedRow .ant-time-picker-input {
  background-color: #ff7979;
}
.teacher-popover {
  width: 45vw;
  p {
    display: flex;
    span {
      flex: 1;
    }
  }
  .ant-divider-inner-text {
    font-size: 12px;
  }
  .top,
  .middle,
  .bottom {
    padding-left: 27px;
    padding-right: 27px;
  }
  .top {
    display: flex;
    padding-top: 22px;
    margin-bottom: 20px;
    img {
      width: 90px;
      height: 90px;
    }
    .top-right {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      span {
        margin: 5px;
      }
      .name-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          display: flex;
          align-items: center;
          img {
            width: 20px;
            height: 20px;
          }
          span:first-child {
            font-size: 28px;
            font-weight: bold;
          }
        }
        .btn-group {
          text-align: right;
        }
        button {
          margin-left: 10px;
        }
      }
      .teaching-style {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        li {
          padding: 2px 4px;
          color: #666666;
          background-color: #f7f7f7;
          border-radius: 6px;
          margin-right: 10px;
          margin-top: 5px;
        }
      }
    }
  }
  .middle {
    .info-title {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
    }
    p {
      color: #666666;
      text-indent: 20px;
      margin: 10px 0px 20px 0px;
      display: block;
    }
  }
  .bottom {
    color: #333333;
    padding-bottom: 20px;
    b {
      font-weight: normal;
      color: #999999;
    }
  }
  .modal-btn-group {
    display: flex;
    justify-content: flex-end;
    button {
      margin: 10px 0 0 10px;
    }
  }
}
.play-item {
  color: rgba(0, 0, 0, 0.85);
  padding-bottom: 5px;
  cursor: pointer;
  &:hover {
    color: #04cb94;
  }
}
.class-wrap {
  width: 100%;
  min-height: calc(100vh - 64px);
  background-color: #fff;
  padding: 16px 20px;
}
</style>
